// vorübergehend
export const handleVoucherTranslation = (voucher) => {
    if (voucher.text.pattern) {
        return voucher.text
    }

    return {
        pattern: voucher.text,
    }
}

export const translations = (state = {}, action) => {
    switch (action.type) {
        case 'SERVER_INIT': {
            // TODO:
            // better transformation...
            const payload = action.payload.model.translation
            const transformed = {
                gender: payload.gender,
                filters: payload.filters,
                sorting: payload.sorting,
                eyecatchers: {
                    voucher: handleVoucherTranslation(
                        payload.eyecatchers.voucher,
                    ),
                    shipping:
                        payload.eyecatchers.shipping.free,
                    sale: payload.eyecatchers.sale,
                },
                layer: {
                    buttons: payload.productLayer.button,
                },
                search: payload.search,
                itemAvailability: payload.itemAvailability,
                productAvailability:
                    payload.productAvailability,
                productsGrid: {
                    pagination: {
                        button: payload.pagination.load,
                        jump: payload.pagination
                            .scrollToTop,
                        status: {
                            pattern:
                                payload.pagination.state,
                        },
                        previousButton:
                            payload.pagination.loadPrevious,
                    },
                    headline: {
                        search: {
                            variables: {},
                            pattern:
                                payload.productsGrid
                                    ?.headline?.search
                                    ?.pattern,
                        },
                        emptySearch: {
                            variables: {},
                            pattern:
                                payload.productsGrid
                                    ?.headline?.emptySearch
                                    ?.pattern,
                        },
                    },
                },
                voucher: {
                    button: payload.voucher.input.button,
                },
                priceInfos: payload.priceInfos,
                uniqueCharacteristics:
                    payload.uniqueCharacteristics,
                dropdownLabel: payload.dropdownLabel,
                prices: payload.prices,
                filterSearch: {
                    ...payload.filterSearch,
                },
                mobileFilter: {
                    ...payload.mobileFilter,
                },
                energyEfficiencyClass: {
                    ...payload.energyEfficiencyClass,
                },
            }
            return transformed
        }

        default:
            return state
    }
}
