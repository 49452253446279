import * as Sentry from '@sentry/react'
import { ExternalScriptError } from './errors'

const getEnvironment = () => {
    return (
        document.querySelector('meta[name="env"]')
            ?.content || 'LOCAL'
    )
}

const getRelease = () => {
    return (
        document.querySelector('meta[name="release"]')
            ?.content || 'LOCAL'
    )
}

const getStoreCode = () => {
    return (
        document.querySelector('meta[name="storeCode"]')
            ?.content || 'LOCAL'
    )
}

export const getFilename = (event) => {
    return event.exception?.values?.[0]?.stacktrace
        ?.frames?.[0]?.filename
}

export const isInternal = (event) => {
    if (event.exception && event.exception.values) {
        const error = event.exception.values[0]
        if (error.stacktrace && error.stacktrace.frames) {
            const lastFrame =
                error.stacktrace.frames[
                    error.stacktrace.frames.length - 1
                ]
            if (
                !lastFrame.filename ||
                lastFrame.filename === '<anonymous>'
            ) {
                return false // Drop the event from global code
            }

            if (
                lastFrame.filename &&
                !lastFrame.filename.includes(
                    window.location.origin,
                )
            ) {
                return false
            }
        }
    }

    return true
}

export const init = () => {
    Sentry.init({
        dsn: 'https://8b76c8a7fe9e42fdb91c4aef0eb6340a@o1228606.ingest.sentry.io/4505192796258304',
        integrations: [new Sentry.BrowserTracing()],
        environment: getEnvironment(),
        release: getRelease(),
        beforeSend: (event) => {
            if (isInternal(event)) {
                event.tags = event.tags || {}
                event.tags['js.source'] = 'int'
                event.message = `${event.message || ''}`
                if (event.exception?.values) {
                    event.exception.values =
                        event.exception.values.map((v) => {
                            return {
                                ...v,
                                value: `${v.value || ''}`,
                            }
                        })
                }
            } else {
                event.tags = event.tags || {}
                event.tags['js.source'] = 'ext'
                event.message = `[ExtJs] ${
                    event.message || ''
                }`
                if (event.exception?.values) {
                    event.exception.values =
                        event.exception.values.map((v) => {
                            return {
                                ...v,
                                value: `[ExtJs] ${
                                    v.value || ''
                                }`,
                            }
                        })
                }
            }

            return event
        },

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.0,
    })

    Sentry.setTag('conversionbuddy-next-js', getRelease())
    Sentry.setTag('storeCode', getStoreCode())

    window.errorLayer &&
        window.errorLayer.forEach((e) => {
            if (e === 'Script error.') {
                Sentry.captureException(
                    new ExternalScriptError(
                        'host - ' + window.location.host,
                    ),
                )
            } else {
                Sentry.captureException(e)
            }
        })
}
