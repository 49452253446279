import { useSelector } from 'react-redux'
import { postEvent } from '../api'
import { clearNullishValues } from '../utils'
import { getSessionStorage } from '../utils/sessionStorage'

export const TrackingTypes = {
    CLICK_OUT: 'clickOut',
    CLICK_OUT_INFO: 'clickOut.info',
    CLICK_OUT_BUTTON: 'clickOut.button',
    CLICK_IN: 'clickIn',
    CLICK_IN_TAG: 'clickIn.tag',
    CLICK_BACK: 'clickBack',
    CLICK_INTERNAL: ['clickInternal'],
    CLICK_INTERNAL_PROMOTION: [
        'clickInternal',
        'promotion',
    ],
    CLICK_INTERNAL_THUMBNAILSLIDE: [
        'clickInternal.thumbnailSlide',
    ],
    CLICK_INTERNAL_FILTER: ['clickInternal', 'filter'],
    CLICK_INTERNAL_LAYER: ['clickInternal', 'layer'],
    CLICK_OUT_AD: 'clickOut.ad',
    CLICK_OUT_RELATED_PRODUCT: 'clickOut.relatedProduct',
    CROSS_OUT_PRODUCT: 'crossOut.product',
    CROSS_OUT_INFO: 'crossOut.info',
    CROSS_OUT_CATEGORY: 'crossOut.category',
    CROSS_OUT_AD: 'crossOut.ad',
    CROSS_OUT_RELATED_PRODUCT: 'crossOut.relatedProduct',
}

export const [createHook, triggerHooks, resetHooks] =
    (() => {
        let hooks = []
        return [
            (type, callback) => {
                const hook = {
                    type,
                    callback,
                }

                hooks.push(hook)
            },
            (trackingData) => {
                const filter = (hook) => {
                    if (
                        hook.type.main === trackingData.type
                    ) {
                        if (hook.type.sub) {
                            return (
                                hook.type.sub ===
                                trackingData.subType
                            )
                        }
                        return true
                    }
                    return false
                }
                const promises = hooks
                    .filter(filter)
                    .map((hook) =>
                        hook.callback(trackingData),
                    )
                return Promise.all(promises)
            },
            () => {
                hooks = []
            },
        ]
    })()

if (typeof window !== 'undefined') {
    window.createCBTrackingHook = createHook
}

export const isEntryType = (type) =>
    [
        TrackingTypes.CLICK_IN,
        TrackingTypes.CLICK_BACK,
        TrackingTypes.CLICK_IN_TAG,
    ].includes(type)

export const resolveTrackingType = (
    type,
    nextGenTracking = false,
) => {
    if (Array.isArray(type)) {
        return {
            type: type[0],
            subType: nextGenTracking ? type[1] : null,
        }
    }

    return {
        type,
    }
}

const getDocument = () => {
    if (typeof document === 'undefined') {
        return null
    }

    return document
}

let referrer = getDocument()?.referrer || ''
export const resetReferrer = () => {
    referrer = ''
}

const getWindow = () => {
    if (typeof window === 'undefined') {
        return null
    }

    return window
}

const isRelative = (target) => {
    return !target.startsWith('http')
}

export const resolveTarget = (target) => {
    // if no target -> self
    if (!target) {
        return getWindow()?.location?.href
    }

    // if relative -> prepend origin
    if (isRelative(target)) {
        return `${getWindow()?.location?.origin}${target}`
    }

    return target
}

export const track = ({
    product,
    sessionId,
    tags,
    target,
    targetProduct,
    targetVariant,
    type,
}) => {
    const t = resolveTarget(target)
    const subType = resolveTrackingType(type, true).subType
    return new Promise((resolve, reject) => {
        const trackingData = clearNullishValues({
            ...resolveTrackingType(type),
            target: t,
            product,
            sessionId,
            tags,
            referrer,
            targetProduct,
            targetVariant,
        })
        triggerHooks({ ...trackingData, subType })
            .catch(() => {})
            .finally(() => {
                postEvent(trackingData, true)
                    .then(() => {
                        referrer = t
                        getSessionStorage().setItem(
                            'sessionId',
                            sessionId || '',
                        )
                        resolve()
                    })
                    .catch(
                        /* istanbul ignore next */ (e) => {
                            console.log(e)
                            reject(e)
                        },
                    )
            })
    })
}

export const useTracking = () => {
    const product = useSelector(
        (state) => state.product?.id,
    )

    const sessionId = useSelector(
        (state) => state.sessionId,
    )

    const storedSessionId =
        getSessionStorage().getItem('sessionId')

    const tags = useSelector((state) => state.tags)

    const getType = (type) => {
        return isEntryType(type) &&
            sessionId === storedSessionId
            ? TrackingTypes.CLICK_BACK
            : type
    }

    return [
        TrackingTypes,
        ({ type, ...payload }) =>
            track({
                product,
                sessionId,
                tags,
                type: getType(type),
                ...payload,
            }),
    ]
}
