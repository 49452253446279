import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Eyecatchers } from '../../../../Layout/Eyecatchers'
import { getStyles, Button } from '@conversionbuddy/Layout'
import { useTracking } from '../../../../../hooks/useTracking'
import { clamp, noop } from '../../../../../utils'
import { useDimensions } from '../../../../../hooks/useDimensions'
import { Swiper } from '../../../../Layout/Swiper'
import { useOverlayState } from '../../../../../hooks/useOverlayState'
import { useSelector } from 'react-redux'

const EyecatchersContainer = styled.div`
    ${getStyles(['productsGrid.product.eyecatchers'])}
    height: 100%;
    @media (hover: none) {
        height: 100%;
    }
`

const StyledImagesContainer = styled.div`
    position: relative;
    width: 100%;

    padding-top: ${(props) =>
        (props.dimensions.height / props.dimensions.width) *
        100}%;

    @media (hover: none) {
        padding-top: calc(
            ${(props) =>
                (props.dimensions.height /
                    props.dimensions.width) *
                100}%
        );
    }
`

const getControlStyles = ({ position, visible }) => {
    if (!visible) {
        return `opacity: 0; ${position}: 0px;`
    }

    return `${position}: 0px;`
}

const StyledImagesSliderControl = styled(Button)`
    cursor: pointer;
    transition: 0.25s ease all;
    position: absolute;
    display: flex;
    ${getControlStyles}
    height: 30px;
    width: 30px;
    background-color: white;
    justify-content: center;
    align-items: center;
    z-index: 10;
    ${(props) =>
        props.position === 'left'
            ? 'border-top-right-radius: 2px; border-bottom-right-radius: 2px;'
            : 'border-top-left-radius: 2px; border-bottom-left-radius: 2px;'}
    ${getStyles(['carousel.control'])}
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    ${(props) =>
        props.position === 'left' &&
        'transform: rotate(180deg);'}

    top: calc(50% - 15px);
    @media screen and (hover: none) {
        display: none;
    }
`

const StyledImagesSliderControlPrevent = styled.div`
    position: absolute;
    height: 40px;
    width: 40px;
    z-index: 9;
    opacity: 0;
    ${(props) =>
        props.position === 'left'
            ? 'left: 0;'
            : 'right: 0;'}
    top: 0;
    bottom: 0;
    margin: auto;
`

const SwiperContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

export const ProductImagesContainer = ({
    imageDimensions,
    show,
    ...props
}) => {
    return show ? (
        <ProductImages
            {...props}
            imageDimensions={imageDimensions}
        />
    ) : (
        <StyledImagesContainer
            dimensions={imageDimensions}
        />
    )
}

ProductImagesContainer.propTypes = {
    show: PropTypes.bool.isRequired,
    imageDimensions: PropTypes.object.isRequired,
}

export const ProductImages = ({
    forcedImage,
    hovered,
    imageDimensions,
    images,
    isFirstProduct,
    product,
}) => {
    const [ref, dimensions] = useDimensions()
    const [activeIndex, setActiveIndex] = useState(0)
    const [TrackingTypes, track] = useTracking()
    const [isOverlayHidden] = useOverlayState()
    const device = useSelector((state) => state.device)

    const handleClickControl = (position) => (e) => {
        e.preventDefault()
        e.stopPropagation()

        setActiveIndex(
            clamp(
                activeIndex +
                    (position === 'left' ? -1 : 1),
                0,
                images.length - 1,
            ),
        )
    }

    const handleSlideChange = ({ activeIndex: index }) => {
        track({
            type: TrackingTypes.CLICK_INTERNAL_THUMBNAILSLIDE,
            targetProduct: product.id,
        })
        setActiveIndex(index)
    }

    const isPreviewable = useMemo(
        () =>
            isFirstProduct &&
            isOverlayHidden &&
            device !== 'DESKTOP',
        [isFirstProduct, isOverlayHidden, device],
    )

    return images?.length > 0 ? (
        <StyledImagesContainer
            data-testid={`productsGrid.images_${product.id}`}
            dimensions={imageDimensions}
        >
            <EyecatchersContainer>
                <Eyecatchers product={product} />
            </EyecatchersContainer>
            <StyledImagesSliderControlPrevent
                position='left'
                onClick={(e) => {
                    e.stopPropagation()
                }}
            />
            <StyledImagesSliderControl
                position='left'
                title='Control left'
                visible={
                    images.length > 1 &&
                    activeIndex !== 0 &&
                    hovered
                }
                onClick={handleClickControl('left')}
            />
            <StyledImagesSliderControlPrevent
                position='right'
                onClick={(e) => {
                    e.stopPropagation()
                }}
            />
            <StyledImagesSliderControl
                position='right'
                title='Control right'
                visible={
                    images.length > 1 &&
                    activeIndex !== images.length - 1 &&
                    hovered
                }
                onClick={handleClickControl('right')}
            />
            <SwiperContainer ref={ref}>
                <Swiper
                    activeIndex={activeIndex}
                    dimension={[
                        dimensions.width,
                        dimensions.height,
                    ]}
                    forcedImage={forcedImage}
                    images={images}
                    isFirstProduct={isFirstProduct}
                    isPreviewable={isPreviewable}
                    spaceBetween={0}
                    onClickItem={noop}
                    onSlideChange={handleSlideChange}
                />
            </SwiperContainer>
        </StyledImagesContainer>
    ) : null
}

ProductImages.propTypes = {
    hovered: PropTypes.bool,
    images: PropTypes.array.isRequired,
    imageDimensions: PropTypes.object.isRequired,
    show: PropTypes.bool,
    isFirstProduct: PropTypes.bool,
    product: PropTypes.object.isRequired,
}

ProductImages.defaultProps = {
    hovered: false,
    show: true,
    isFirstProduct: false,
}
