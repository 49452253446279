import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useOverlayState } from '../../hooks/useOverlayState'
import { useTracking } from '../../hooks/useTracking'
import { useExternalNavigateReplaceState } from '../../hooks/useExternalNavigate'
import { useDimensions } from '../../hooks/useDimensions'
import { useWindowResize } from '../../hooks/useWindowResize'
import { useScrollLock } from '../../hooks/useScrollLock'
import { usePromotion } from '../../hooks/usePromotion'
import { getStyles } from '@conversionbuddy/Layout'
import { Details } from './Details'
import { Images } from './Images'
import { Header } from './Header'
import { Banner } from './Banner'
import { useDeepQuery } from '../../hooks/useQuery'
import { useOffset } from '../../providers/OffsetProvider'
import { Placeholder } from '../Layout/Placeholder'
import { uniqBy } from '../../utils'
import { useIsIOSSafariPrivate } from '../../hooks/useIsIOSSafariPrivate'

const useIsSSR = () => {
    const [isSSR, setIsSSR] = useState(true)
    useEffect(() => {
        setIsSSR(false)
    }, [])
    return isSSR
}

export const BANNER_SPACING = 10

// utils BEGIN
export const getSliderDimensions = (
    layerDimensions,
    imageDimensions,
) => {
    const maxHeightMobile = 300
    const widthMobile = Math.min(
        Math.round(
            (maxHeightMobile / imageDimensions.height) *
                imageDimensions.width,
        ),
        285,
    )

    const maxHeightTablet = 300
    const widthTablet = Math.min(
        Math.round(
            (maxHeightTablet / imageDimensions.height) *
                imageDimensions.width,
        ),
        500,
    )

    const maxHeightDesktop =
        (layerDimensions?.height || 400) - 30
    const maxWidthDesktop = 285

    const hd = Math.round(
        (maxWidthDesktop * imageDimensions.height) /
            imageDimensions.width,
    )

    const multDesktop =
        hd > maxHeightDesktop ? maxHeightDesktop / hd : 1

    return [
        [widthMobile, maxHeightMobile],
        [widthTablet, maxHeightTablet],
        [
            Math.round(285 * multDesktop),
            Math.round(multDesktop * maxHeightDesktop),
        ],
    ]
}

export const getRedirectParams = ({
    activeSku,
    addToCart,
    product,
    sku,
    targetUrl,
}) => {
    const variant = sku || activeSku || product.skus[0]
    if (targetUrl) {
        return {
            variant,
            url: targetUrl,
            exact: !!sku,
        }
    }

    return {
        variant,
        url: addToCart ? variant.addToCartUrl : variant.url,
        exact: !!sku,
    }
}
// utils END

// styled BEGIN
const ProductLayerWrapper = styled.div`
    ${getStyles(['productLayer.wrapper'])}
`

const ProductLayerBackdrop = styled.div`
    ${getStyles(['productLayer.backdrop'])}
`

const ProductLayerModal = styled.div`
    ${getStyles(['productLayer.modal'])}

    top: ${(props) => 20 + props.mobileOffset}px;
    @media screen and (min-width: 900px) {
        top: ${(props) => props.offset}px;
        border-top-right-radius: 4px;
    }
`
// styled END

const useLayerDimensions = () => {
    const windowDimensions = useWindowResize()
    const [ref, dimensions] = useDimensions(false)
    const banner = useSelector(
        (state) => state.bannerBySlots.productLayerTop?.[0],
    )

    // calculate banner height
    // desktop: 900px wide -> 900 * (slot.height / slot.width)
    // mobile: irrelevant, da keine zentrierung des layers notwendig

    let bannerHeight = {}
    if (banner) {
        const bannerDesktopSlotSize =
            banner.sources[0].slotSize

        bannerHeight = {
            desktop: bannerDesktopSlotSize.height,
            tablet: null,
            mobile: null,
        }
    }

    // fits banner or should center?
    // ((modal height w/o banner) / 2 + banner height) < screen height / 2
    const fits = bannerHeight.desktop
        ? dimensions.height / 2 +
              bannerHeight.desktop +
              BANNER_SPACING <
          windowDimensions.height / 2
        : true

    return [
        ref,
        dimensions,
        bannerHeight,
        windowDimensions,
        fits,
    ]
}

export const ProductLayer = ({
    close,
    externalNavigate,
    isIOSSafariPrivate,
    isSSR,
    offset,
}) => {
    const [
        selectedRelatedProduct,
        setSelectedRelatedProduct,
    ] = useState(null)

    const activeSku = useSelector(
        (state) => state.activeSku,
    )
    const imageDimensions = useSelector(
        (state) => state.general?.dimensions,
    )
    const product = useSelector((state) => state.product)

    const getImages = () => {
        const pred = (a, b) => a === b
        if (activeSku?.images) {
            return uniqBy(
                [...activeSku.images, ...product.images],
                pred,
            )
        }

        return uniqBy(product.images, pred)
    }
    const promotion = usePromotion(product, activeSku)
    const [TrackingTypes, track] = useTracking()
    const [query, setQuery] = useDeepQuery(true)

    const handleDeselectRelatedProduct = () => {
        setSelectedRelatedProduct()
    }

    const handleSelectRelatedProduct = (relatedProduct) => {
        setSelectedRelatedProduct(relatedProduct)
    }

    const handleClose = () => {
        track({
            type: TrackingTypes.CLICK_INTERNAL_LAYER,
        })
        close()
    }

    const handleClickOut = ({
        addToCart = false,
        relatedProduct,
        sku,
        targetUrl,
        trackingTypeOut = TrackingTypes.CLICK_OUT,
    } = {}) => {
        const { exact, url, variant } = getRedirectParams({
            activeSku,
            addToCart,
            product,
            sku,
            targetUrl,
            relatedProduct,
        })

        const p = relatedProduct || product

        if (promotion && !isIOSSafariPrivate) {
            return setQuery(
                {
                    ...query,
                    'cbjs.voucherLayer': '1',
                    'cbjs.productLayer': '0',
                },
                TrackingTypes.CLICK_INTERNAL,
                // history state
                {
                    voucher: {
                        redirectUrl: url,
                        sku: variant,
                        product: p,
                        trackingType: trackingTypeOut,
                        trackingPayload: {
                            targetVariant: exact
                                ? sku.sku
                                : null,
                            targetProduct: p,
                        },
                    },
                },
            )
        }

        return externalNavigate(
            url,
            {
                'cbjs.voucherLayer': '0',
                'cbjs.productLayer': '0',
            },
            {
                targetVariant: exact ? sku.sku : null,
                targetProduct: p.id,
                type: trackingTypeOut,
            },
        )
    }

    const [ref, dimensions, bannerHeight, _, fits] =
        useLayerDimensions(true)

    const sliderDimensions = getSliderDimensions(
        dimensions,
        imageDimensions,
    )

    return (
        <ProductLayerWrapper>
            <ProductLayerBackdrop
                data-testid='productLayer.backdrop'
                onClick={handleClose}
            />
            <ProductLayerModal
                ref={ref}
                mobileOffset={offset}
                offset={
                    fits || isSSR
                        ? 0
                        : (bannerHeight.desktop +
                              BANNER_SPACING) /
                          2
                }
                role='dialog'
            >
                <Banner onClick={handleClickOut} />
                <Header
                    onClickClose={handleClose}
                    onClickOut={handleClickOut}
                />
                <Placeholder id='beforeProductLayerImages' />
                <Images
                    forcedImage={
                        selectedRelatedProduct?.thumbnail
                    }
                    imageDimensions={imageDimensions}
                    images={getImages()}
                    isSSR={isSSR}
                    product={product}
                    sliderDimensions={sliderDimensions}
                    onClickImage={handleClickOut}
                />
                <Details
                    activeSku={activeSku}
                    dimensions={dimensions}
                    product={product}
                    onClick={handleClickOut}
                    onDeselectRelatedProduct={
                        handleDeselectRelatedProduct
                    }
                    onSelectRelatedProduct={
                        handleSelectRelatedProduct
                    }
                />
            </ProductLayerModal>
        </ProductLayerWrapper>
    )
}

ProductLayer.propTypes = {
    close: PropTypes.func.isRequired,
    externalNavigate: PropTypes.func.isRequired,
    isIOSSafariPrivate: PropTypes.bool,
    isSSR: PropTypes.bool,
    offset: PropTypes.any,
}

ProductLayer.defaultProps = {
    isIOSSafariPrivate: false,
    isSSR: false,
    offset: 0,
}

export const ProductLayerContainer = () => {
    const isSSR = useIsSSR()
    const isIOSSafariPrivate = useIsIOSSafariPrivate()
    const [hidden, close] = useOverlayState(
        isIOSSafariPrivate,
    )

    const [isRedirecting, setIsRedirecting] =
        useState(false)

    const externalNavigate =
        useExternalNavigateReplaceState(isIOSSafariPrivate)
    useScrollLock(!hidden)

    const enhancedNavigate = (...args) => {
        setIsRedirecting(true)
        externalNavigate(...args)
    }
    const [_, offset] = useOffset()
    const [calcOffset, setCalcOffset] = useState(0)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (!hidden && offset) {
                window.scrollTo &&
                    window.scrollTo(0, offset)
            }

            setCalcOffset(offset)
        }
    }, [hidden, offset])

    useEffect(() => {
        const handler = () => {
            setIsRedirecting(false)
        }

        if (typeof window !== 'undefined') {
            window.addEventListener('pageshow', handler)

            return () =>
                window.removeEventListener(
                    'pageshow',
                    handler,
                )
        }
    }, [])

    return hidden && !isRedirecting ? null : (
        <ThemeProvider
            theme={(theme) => ({
                ...theme,
                breakpoints: [
                    {
                        name: 'xs',
                        value: '0px',
                    },
                    {
                        name: 'sm',
                        value: '576px',
                    },
                    {
                        name: 'md',
                        value: '900px',
                    },
                ],
            })}
        >
            <ProductLayer
                close={close}
                externalNavigate={enhancedNavigate}
                isIOSSafariPrivate={isIOSSafariPrivate}
                isSSR={isSSR}
                offset={calcOffset}
            />
        </ThemeProvider>
    )
}

ProductLayerContainer.propTypes = {
    isSSR: PropTypes.bool,
}

ProductLayerContainer.defaultProps = {
    isSSR: false,
}
